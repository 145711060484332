import React from "react"
import Pages from "../../../data/PagesData"
import { LinkButton, LogoButton } from "../atoms/Button"
import styled from "styled-components"

const HeaderInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 80%;
    height: calc( 96px - 57px );
`

const HeaderButtons = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: auto;
`

const TopHeaderLinkDivider = styled.div`
    font-size: 13.5px;
    font-weight: 700;
    color: white;
    margin: 0px 7px 0px 7px;
`

const HeaderLinkDivider = styled.div`
    font-size: 13.5px;
    font-weight: 700;
    margin: 0px 7px 0px 7px;
`

const HeaderInner = (props) => {

    return (
        <HeaderInnerWrapper>
            <LogoButton color={props.color} />
            <HeaderButtons>
                {Pages.slice(1, 6).map((p, idx) => {
                    if (p.name !== "INDEX") {
                        return (
                            <React.Fragment key={idx}>
                                <LinkButton label={p.name} linkto={p.linkto} color={props.color} />
                                {(idx !== Pages.length - 1 && props.color === "white")
                                    ? <TopHeaderLinkDivider>/</TopHeaderLinkDivider>
                                    : null
                                }
                                {(idx !== Pages.length - 1 && props.color === "black")
                                    ? <HeaderLinkDivider>/</HeaderLinkDivider>
                                    : null
                                }
                            </React.Fragment>
                        )
                    } else {
                        return null
                    }
                })}
            </HeaderButtons>
        </HeaderInnerWrapper>
    )
}

export default HeaderInner