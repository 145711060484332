const Pages = [
    {name: "INDEX", linkto: "/"},
    {name: "NEWS", linkto: "/news"},
    {name: "COMPANY", linkto: "/company"},
    {name: "SERVICE", linkto: "https://www.synq-platform.com/"},
    {name: "RECRUIT", linkto: "https://note.quando.jp/"},
    {name: "CONTACT", linkto: "/contact"},
    {name: "TERM", linkto: "https://www.synq-platform.com/terms"},
    {name: "PRIVACY", linkto: "https://www.synq-platform.com/policy"},
]

export default Pages