import React from "react"
import styled from "styled-components"
import { MobileLogoButton, TopMobileHeaderMenuButton } from "../atoms/MobileButton"

const MobileHeaderInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 39px;
`
const MobileHeaderInner = (props) =>{
    return(
        <MobileHeaderInnerWrapper>
            {/* <LogoButton color={props.color} /> */}
            <MobileLogoButton color={props.color} />
            <TopMobileHeaderMenuButton color={props.color} onClick={props.onClickHamburgerMenu} />
        </MobileHeaderInnerWrapper>
    )
}
export default MobileHeaderInner