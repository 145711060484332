import React, { useState } from "react"
import { Slide } from "@material-ui/core"
import styled from "styled-components"
import MobileHeaderInner from "../molecules/MobileHeaderInner"
import { LogoButton, SNSIconButton } from "../../PC/atoms/Button"
import Pages from "../../../data/PagesData"
import { CloseHamburgerMenuButton } from "../atoms/MobileButton"
import { FaceBookIconSVG, NoteIconSVG, TwitterIconSVG } from "../../Images/svg"

const MobileHeaderOuter = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64px;
    background: white;
    border-bottom: 1px solid #f1f1f1;
`

const MobileHeaderOuterTop = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64px;
    background: none;
`

const MobileHeaderMenuOuter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64px;
`

const MobileHamburgerMenuOuter = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: black;
`

const MobileHeaderInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 39px;
`

const MobileHamburgerMenuContents = styled.div`
    width: 85%;
    min-width: 280px;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const MobileHamburgerMenuFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 85%;
    min-width: 280px;
    height: 140px;
`

const Copyright = styled.p`
    color: white;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
`

const MobileMenuContent = styled.a`
    color: white;
    font-size: 26px;
    font-weight: 500;
    text-decoration: none;
`

const SnsButtons = styled.div`
    display: flex;
    width: 80px;
    height: 24px;
    justify-content: space-between;
    margin-bottom: 10px;
`

const MobileHeader = (props) =>{

    const [viewingYoffset,　setYoffset] = useState(0)
    const onServerSideBuild = typeof window === `undefined`

    setInterval(function() {
        const latestYoffset = (onServerSideBuild) ? 0 : window.pageYOffset
        if (viewingYoffset !== latestYoffset) {
            setYoffset(window.pageYOffset)
        }
    }, 100)

    const indexHeader = props.page === "INDEX"
    const indexCondition = (indexHeader && !onServerSideBuild) ? viewingYoffset > window.innerHeight : false
    const otherCondition = (!indexHeader && !onServerSideBuild) ? viewingYoffset > window.innerWidth * 0.78 : false
    const condition = (indexHeader) ? indexCondition : otherCondition
    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <>
            {(!menuOpen) &&
                <>
                    {condition
                        ?
                            <MobileHeaderOuter>
                                <MobileHeaderInner color="black" onClickHamburgerMenu={setMenuOpen}/>
                            </MobileHeaderOuter>
                        :
                            <MobileHeaderOuterTop>
                                <MobileHeaderInner color="white" onClickHamburgerMenu={setMenuOpen}/>
                            </MobileHeaderOuterTop>
                    }
                </>
            }
            <Slide in={menuOpen} mountOnEnter unmountOnExit>
                <MobileHamburgerMenuOuter>
                    <MobileHeaderMenuOuter>
                        <MobileHeaderInnerWrapper>
                            <LogoButton color="white" />
                            <CloseHamburgerMenuButton onClickCloseMenu={setMenuOpen}/>
                        </MobileHeaderInnerWrapper>
                    </MobileHeaderMenuOuter>
                    <MobileHamburgerMenuContents>
                        {Pages.map((page, index) => {
                            if (page.name === "INDEX") {
                                return null
                            } else {
                                return (
                                    <MobileMenuContent
                                        key={`MobileMenuContent${index}`}
                                        href={page.linkto}
                                    >
                                        {page.name}
                                    </MobileMenuContent>
                                )
                            }
                        })}
                    </MobileHamburgerMenuContents>
                    <MobileHamburgerMenuFooter>
                        <SnsButtons>
                            <SNSIconButton linkto="https://www.facebook.com/QUANDOInc/">
                                <FaceBookIconSVG />
                            </SNSIconButton>
                            <SNSIconButton linkto="https://twitter.com/SynQRemote">
                                <TwitterIconSVG />
                            </SNSIconButton>
                            <SNSIconButton linkto="https://note.com/synq">
                                <NoteIconSVG />
                            </SNSIconButton>
                        </SnsButtons>
                        <Copyright>© 2020 QUANDO Inc. All Rights Reserved.</Copyright>
                    </MobileHamburgerMenuFooter>
                </MobileHamburgerMenuOuter>
            </Slide>
        </>
    )
}

export default MobileHeader