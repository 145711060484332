import React from "react"
import styled from "styled-components"

const IndexHeroScrollWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 115px;
`

const IndexHeroScrollLetters = styled.p`
    display: inline-block;
    width: 100%;
    font-size: 13.5px;
    font-weight: 700;
    color: white;
    text-align: center;
`

const IndexHeroScrollLine = styled.div`
    display: inline-block;
    width: 1px;
    height: 75px;
    background: white;
    margin: auto;
`

export const IndexHeroScroll = () => {

    return (
        <IndexHeroScrollWrapper>
            <IndexHeroScrollLetters>SCROLL</IndexHeroScrollLetters>
            <IndexHeroScrollLine />
        </IndexHeroScrollWrapper>
    )
}

const HorizontalLineOuter = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`

const HorizontalLineInner = styled.div`
    display: inline-block;
    width: 70%;
    min-width: 900px;
    height: 0.5px;
    background: #CCCCCC;
`

export const HorizontalLine = () => {

    return (
        <HorizontalLineOuter>
            <HorizontalLineInner />
        </HorizontalLineOuter>
    )
}